const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

function urlB64ToUint8Array(base64String) {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function createSubscriptionKeys(subscription) {
  var keys = {
    p256dh: subscription.toJSON().keys.p256dh,
    auth: subscription.toJSON().keys.auth,
  };
  subscription["keys"] = keys;
  return subscription;
}

export function register(config) {
  if ("serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `/service-worker.js`;
      console.log('isLocalhost', isLocalhost)
     // if (localhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
      //  checkValidServiceWorker(swUrl, config);
       // navigator.serviceWorker.ready.then(() => {
        //  console.log(
         //   "This web app is being served cache-first by a service "
         // );
       // });
     // } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
     // }
    });
  }
}

function registerValidSW(swUrl, config) {
	let isSubscribed;
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      Notification.requestPermission().then((permission) => {
        if (permission == 'granted') {
          registration.pushManager
            .getSubscription()
            .then(function (subscription) {
              isSubscribed = !(subscription === null);
              if (isSubscribed) {
                //User Already Subscribed
              } else {
                const applicationKey = store(`${appId}_applicationKey`)
                console.log('applicationKey=======')
                registration.pushManager
                  .subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlB64ToUint8Array(
                      applicationKey
                    ),
                  })
                  .then(function (subscription) {
                    subscription = createSubscriptionKeys(subscription);
                    store(`browserPush`, true);
                    store(
                      `${appId}_pushSubscription`,
                      JSON.stringify(subscription)
                    );
                    putAppAndUserData(subscription);

                    isSubscribed = true;
                  })
                  .catch(function (err) {
                    store(`browserPush`, false);
                    putAppAndUserData(subscription);
                  });
              }
            });
        }
      })
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              console.log(
                "New content is available and will be used when all"
              );
              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              console.log("Content is cached for offline use.");

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
