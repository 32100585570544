import * as React from "react";
import { View, Text, TouchableOpacity } from "react-native";

import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

export default function App() {
  React.useEffect(() => {
    const initializeAppice = async () => {
      // Wait for Appice SDK script to load
      await new Promise((resolve) => {
        var jsElm = document.createElement("script");
        jsElm.type = "text/javascript";
        jsElm.async = true;
        jsElm.src = "https://webcdn.appice.io/appicewebsdkv2/appicestg.js";
        //jsElm.src = 'appice.js'
        jsElm.onload = resolve;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(jsElm, s);
      });

      // Initialize Appice after the script is loaded
      if (typeof Appice !== "undefined" && Appice.initializeWithConfig) {
        var swPath = "/serviceworker.js";
        Appice.initializeWithConfig(
          swPath,
          "1.0.0",
          "abc",
          "b8a0da985627dc6933e156c69f9e90017925f8ad",
          "5e4bce9a3d400534d7dc67a7",
          "d985715d1bb48942d36d5d08de3b6a8c",
          "BLwSB4KT-CUIHHVnmO-quwJzqR3slTmVvJvIsSuCMlqhJos85BwXH16V2mJWCWdefv7eooNzeewO9PiF9IlDKG8",
          "",
          "",
          "GCC",
          "https://gccapi.appice.io"
        );
       
        
      } else {
        console.error("Appice or initializeWithConfig not available");
      }
    };

    initializeAppice();
  }, []);
  const handleClick = () => {
    alert('Button clicked!');
    const obj = {
      productId : '123',
      skuId : '345'
    }
    Appice.recordEvent({
      key: 'buyNow',
      segment: obj,
    });
    // You can perform any action or navigation here
  };
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text>Universal (offline) React with Expo</Text>
      <TouchableOpacity onPress={handleClick}>
          <View style={{ backgroundColor: 'blue', padding: 10, borderRadius: 5 }}>
            <Text style={{ color: 'white', textAlign: 'center' }}>Click me</Text>
          </View>
        </TouchableOpacity>
    </View>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
